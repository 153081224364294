$(document).on('turbo:load', function () {
  const _loadUserPrefs = () => {
    const userPrefs = window.localStorage.getItem('user-preferences');

    if (!userPrefs) {
      const newUserPrefs = {
        hasAcceptedCookiePolicy: false,
      };

      window.localStorage.setItem(
        'user-preferences',
        JSON.stringify(newUserPrefs),
      );

      return newUserPrefs;
    }

    return JSON.parse(userPrefs);
  };

  const _saveUserPrefs = (newPrefs) => {
    window.localStorage.setItem('user-preferences', JSON.stringify(newPrefs));
  };

  const userPrefs = _loadUserPrefs();

  $('#cookie-policy-bar').on('hidden.bs.toast', (event) => {
    $(event.currentTarget).addClass('hidden');
  });

  if (userPrefs.hasAcceptedCookiePolicy) {
    $('#cookie-policy-bar').addClass('hidden');
  } else {
    $('#cookie-policy-bar').toast('show');
    $('#cookie-policy-accept').click(() => {
      userPrefs.hasAcceptedCookiePolicy = true;

      _saveUserPrefs(userPrefs);
      $('#cookie-policy-bar').toast('hide');
    });
  }
});
