$(document).on('turbo:load', function () {
  let windowResizeHandler = null;
  const BASE_PADDING = 12;

  const setWrapperPadding = (event) => {
    const animate = !!event;
    const _getAgentHeaderHeight = () => {
      return (
        document.querySelector('[data-hq-agent-header]')?.clientHeight || 0
      );
    };

    const _getNavHeight = () => {
      return document.querySelector('[data-hq-nav]')?.clientHeight || 0;
    };

    if (animate) {
      return $(
        'div[data-reflex-permanent].d-none.map-side-lg, div#main-wrapper',
      ).animate(
        {
          paddingTop: BASE_PADDING + _getNavHeight() + _getAgentHeaderHeight(),
        },
        500,
      );
    }

    return $(
      'div[data-reflex-permanent].d-none.map-side-lg, div#main-wrapper',
    ).css(
      'padding-top',
      BASE_PADDING + _getNavHeight() + _getAgentHeaderHeight(),
    );
  };

  // Handle closing the "Paid subscriber login" banner
  $('#close-hoodq-header, #close-hoodq-header-sm').click(function () {
    $('#hoodq-header').slideUp(500, function () {
      $.ajax({
        type: 'POST',
        url: '/agents_closable_header',
        data: {
          authenticity_token: $('meta[name="csrf-token"]').attr('content'),
          header_status: 'closed',
        },
        success: setWrapperPadding,
        error: setWrapperPadding,
      });
    });
  });

  // Handle viewport resizing
  $(window).on('resize', function () {
    clearTimeout(windowResizeHandler);

    windowResizeHandler = setTimeout(setWrapperPadding, 100);
  });

  // Init
  setWrapperPadding();
});
