/**
 * Directory – Directory & Listing Bootstrap 4 Theme v. 1.6.1
 * Homepage: https://themes.getbootstrap.com/product/directory-directory-listing-bootstrap-4-theme/
 * Copyright 2020, Bootstrapious - https://bootstrapious.com
 */

'use strict';

$(document).on('turbo:load', function () {
  window.scrollTo(0, 0);

  // ------------------------------------------------------- //
  //   Scroll to top button
  // ------------------------------------------------------ //

  $(window).on('scroll', function () {
    if ($(window).scrollTop() >= 1500) {
      $('#scrollTop').fadeIn();
    } else {
      $('#scrollTop').fadeOut();
    }
  });

  $('#scrollTop').on('click', function () {
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      1000,
    );
  });

  // ------------------------------------------------------- //
  // Adding fade effect to dropdowns
  // ------------------------------------------------------ //

  $.fn.slideDropdownUp = function () {
    $(this).fadeIn().css('transform', 'translateY(0)');
    return this;
  };
  $.fn.slideDropdownDown = function (movementAnimation) {
    if (movementAnimation) {
      $(this).fadeOut().css('transform', 'translateY(30px)');
    } else {
      $(this).hide().css('transform', 'translateY(30px)');
    }
    return this;
  };

  $('.navbar .dropdown').on('show.bs.dropdown', function (e) {
    $(this).find('.dropdown-menu').first().slideDropdownUp();
  });
  $('.navbar .dropdown').on('hide.bs.dropdown', function (e) {
    var movementAnimation = true;

    // if on mobile or navigation to another page
    if (
      $(window).width() < 992 ||
      (e.clickEvent && e.clickEvent.target.tagName.toLowerCase() === 'a')
    ) {
      movementAnimation = false;
    }

    $(this).find('.dropdown-menu').first().slideDropdownDown(movementAnimation);
  });

  // ------------------------------------------------------- //
  //    Collapse button control (used for more/less filters)
  // ------------------------------------------------------ //

  $('.btn-collapse').each(function () {
    var button = $(this),
      collapseId = button.attr('data-target');

    if ($(collapseId).length) {
      var collapseElement = $(collapseId);

      $(collapseElement).on('hide.bs.collapse', function () {
        button.text(button.attr('data-collapsed-text'));
      });

      $(collapseElement).on('show.bs.collapse', function () {
        button.text(button.attr('data-expanded-text'));
      });
    }
  });

  // ------------------------------------------------------- //
  //   Bootstrap tooltips
  // ------------------------------------------------------- //

  $('[data-toggle="tooltip"]').tooltip();

  // ------------------------------------------------------- //
  //   Object Fit Images
  // ------------------------------------------------------- //

  objectFitImages();
});
